import { render, staticRenderFns } from "./LifetimePopup.vue?vue&type=template&id=171b5dad&scoped=true"
import script from "./LifetimePopup.vue?vue&type=script&lang=js"
export * from "./LifetimePopup.vue?vue&type=script&lang=js"
import style0 from "./LifetimePopup.vue?vue&type=style&index=0&id=171b5dad&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "171b5dad",
  null
  
)

export default component.exports